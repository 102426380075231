.Layout-container {
  background-color: #f5f5f5;
}

.Picture-container {
  height: 100%;
  width: 100%;
  border-radius: 10px;
}

.Picture-logo {
  min-height: 50%;
  min-width: 50%;
  max-width: 60%;
  max-height: 60%;
  margin: auto;
}

.Carousel-container {
  display: flex;
  flex: 1;
  justify-content: center;
  background-color: #f5f5f5;
}

.Balance-container {
  display: flex;
  flex-direction: column;
  background-color: #068e81;
  border-radius: 26px;
  align-items: center;
}

.Font-title {
  font-size: 26px;
  font: bold;
}

.Font-description {
  font-size: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

.Font-title-footer {
  font-size: 20px;
  font: bold;
  text-align: center;
}

.Font-description-footer {
  font-size: 16px;
}

.Adress-container {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
}

.Header-container {
  display: flex;
  padding-bottom: 35px;
  padding-top: 35px;
  background-color: #f5f5f5;
}

.Body-container {
  display: flex;
  flex-direction: column;
}

.Footer-container {
  display: flex;
  justify-content: center;
}
