.Cards-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px 10px;
    margin: 20px;
    justify-content: center;
}

.Main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
}
