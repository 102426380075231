.Card-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 20px;
    padding-right: 10px;
    padding-left: 10px;
    max-width: 300px;
    max-height: 300px;
    background-color: #8c301b;
    border-radius: 12px;
    color: #f5f5f5;
  }

.Header-text {
  font-size: 16px;
  text-align: left;
}

.Title-text {
  font-size: 16px;
  text-align: left;
}

.Descripition-text {
  font-size: 14px;
  text-align: left;
}

.Description-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* margin-top: 10px;
  padding-left: 10px;
  padding-right: 10px; */
}

.Descripition-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Buttons-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}